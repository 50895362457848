// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: '/api/v1/',
  apiUrl: 'https://demo-cattle-back.cds.com.py/api/v1/',
  // apiUrl: 'http://localhost:8888/api/v1/',
  // apiUrl: 'http://51.15.15.75:8880/api/v1/',
  sentryUrl: 'https://402f460bbdaf4447b63a7beb91300f5e@o4504123932082176.ingest.sentry.io/4505042378096640',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
